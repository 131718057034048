import { prefix, ajax } from './common';

export async function getTestimonials(pagination = 15, page = 1) {
  return await ajax({
    url: prefix + '/testimonials',
    data: {
      pagination: pagination,
      page
    }
  });
}

export async function getTestimonial(testimonial_id) {
  return await ajax({
    url: prefix + '/testimonials/' + testimonial_id,
  });
}

export async function createTestimonial(testimonialInfo) {
  return await ajax({
    method: 'POST',
    url: prefix + '/testimonials',
    data: {
      testimonial_text: testimonialInfo.testimonial_text,
      testimonial_date: testimonialInfo.testimonial_date,
      testimonial_status: testimonialInfo.testimonial_status,
      testimonial_site: testimonialInfo.testimonial_site,
      testimonial_modified: testimonialInfo.testimonial_modified
    }
  });
}

export async function editTestimonial(testimonialInfo) {
  return await ajax({
    method: 'PUT',
    url: prefix + '/testimonials/' + testimonialInfo.testimonial_id,
    data: {
      testimonial_text: testimonialInfo.testimonial_text,
      testimonial_date: testimonialInfo.testimonial_date,
      testimonial_status: testimonialInfo.testimonial_status,
      testimonial_site: testimonialInfo.testimonial_site
    }
  });
}

export async function deleteTestimonial(testimonialInfo) {
  return await ajax({
    method: 'DELETE',
    url: prefix + '/testimonials/' + testimonialInfo.testimonial_id,
    data: {
      testimonial_text: testimonialInfo.testimonial_text,
      testimonial_date: testimonialInfo.testimonial_date,
      testimonial_status: testimonialInfo.testimonial_status,
      testimonial_site: testimonialInfo.testimonial_site
    }
  });
}

export async function getRandomTestimonials(count = 1) {
  return await ajax({
    url: prefix + '/testimonials/random',
    data: {
      count
    }
  });
}

