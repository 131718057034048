import React from 'react';
import Head from 'next/head';
import Link from 'next/link';
import * as adsAjax from '../ajax/ads';
import { useRouter } from 'next/router';
import * as cartAjax from '../ajax/cart';
import Render from '../components/Render';
import type { Brand } from '../types/Brand';
import * as brandsAjax from '../ajax/brands';
import { useAjax } from '@developers/use-ajax';
import * as accountsAjax from '../ajax/accounts';
import styles from '../styles/HomePage.module.css';
import * as equipmentAjax from '../ajax/equipment';
import CartHistory from '../components/CartHistory';
import { useAuth } from '@developers/single-sign-on';
import Testimonials from '../components/Testimonials';
import useScreenResize from '../hooks/useScreenResize';
import { useScreenSize } from '../hooks/useScreenSize';
import * as testimonialsAjax from '../ajax/testimonials';
import DynamicAdCarousel from '../components/DynamicAdCarousel';
import GuidedEquipmentSearch from '../components/GuidedEquipmentSearch';

interface Props {
  brands: Brand[];
}

interface Testimonial {
  testimonial_text: string;
  testimonial_id: number;
}

// This function gets called at build time on server-side.
// It won't be called on client-side
export async function getStaticProps(context) {
  const brands = await (await brandsAjax.getBrands(true)).json();

  return {
    props: {
      brands
    },
    revalidate: 86400, // 24hr
  }
}

interface Props {
  brands: Brand[];
}

function Home(props: Props) {

  const router = useRouter();

  // Don't actually need these variables. Just need the page to rerender if the screen size changes after the initial render.
  const [width, height] = useScreenResize();

  const [accountType, setAccountType] = React.useState('');

  const [testimonials, getRandomTestimonials, getRandomTestimonialMeta] = useAjax(testimonialsAjax.getRandomTestimonials, [] as Testimonial[]);
  const [cartHistory, getCartHistory, getCartHistoryMeta] = useAjax(accountsAjax.getCartHistory, []);
  const [discountLevel, getDiscountLevel, getDiscountLevelMeta] = useAjax(accountsAjax.getDiscountLevel, '');
  const [updatedCart, addProductToCart, addProductToCartMeta] = useAjax(cartAjax.addProductToCart, {});
  const [ads, getCarouselAds, getCarouselAdsMeta] = useAjax(adsAjax.getCarouselAds, []);


  const [equipmentTypes, getEquipmentTypes, getEquipmentTypesMeta] = useAjax(equipmentAjax.getEquipmentTypesForBrand, []);
  const [equipmentSubtypes, getEquipmentSubtypesFromType, getEquipmentSubtypesFromTypeMeta] = useAjax(equipmentAjax.getEquipmentSubtypesFromType, []);
  const [equipment, getEquipmentByBrandAndSubtype, getEquipmentByBrandAndSubtypeMeta] = useAjax(equipmentAjax.getEquipmentByBrandAndSubtype, []);

  const [cookiePresent, setCookiePresent] = React.useState(false);

  React.useEffect(() => {
    getRandomTestimonials(3);

    const account_info_cookie = document.cookie.replace(/(?:(?:^|.*;\s*)account_info\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    if (account_info_cookie) {
      setCookiePresent(true);
      const account_info_array = decodeURIComponent(account_info_cookie).split('|')

      const account_id = account_info_array[0];
      const account_type = account_info_array[1];

      setAccountType(account_type)

      getCarouselAds(account_type);
      getCartHistory(parseInt(account_id));
      getDiscountLevel(parseInt(account_id));
    } else {
      getCarouselAds();
    }
  }, []);

  if (!cookiePresent) {
    return (
      <>
        <Head>
          <title>Fitness and Exercise Equipment Repair Parts</title>
          <meta
            name="description"
            content={'Find spare or replacement parts for exercise and fitness equipment. Use our diagrams and parts lists to locate the right part and extend the life of your treadmill, cross-trainer, bike, elliptical, stepper, or strength machine!'}
          />
          <meta httpEquiv="content-language" content="en-us" />
        </Head>
        <div className={styles.main_container}>
          <div className={styles.top_content_container}>
            <Render if={width >= 745}>
              <div className={styles.testimonials_container}>
                <Testimonials testimonials={testimonials} />
              </div>
            </Render>
            <div className={styles.carousel_and_cart_history_container}>
              <div className={styles.carousel_container}>
                <DynamicAdCarousel 
                  ads={ads}
                  adsLoading={getCarouselAdsMeta.loading}
                  width={width}
                  router={router}
                />
              </div>
              <div className={styles.guided_search_container}>
                <GuidedEquipmentSearch
                  router={router}
                  brands={props.brands}
                  equipmentTypes={equipmentTypes}
                  equipmentSubTypes={equipmentSubtypes}
                  equipment={equipment}
                  getEquipmentTypes={getEquipmentTypes}
                  equipmentTypesMeta={getEquipmentTypesMeta}
                  getEquipmentSubtypesFromType={getEquipmentSubtypesFromType}
                  equipmentSubtypesMeta={getEquipmentSubtypesFromTypeMeta}
                  getEquipmentByBrandAndSubtype={getEquipmentByBrandAndSubtype}
                  equipmentMeta={getEquipmentByBrandAndSubtypeMeta}
                />
              </div>
              <div className={styles.bottom_content_container}>
                <ShopByBrands />
                <Welcome />
                <SearchTipsLink />
                <Render if={width <= 744}>
                  <div className={styles.testimonials_container}>
                    <Testimonials testimonials={testimonials} />
                  </div>
                </Render>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Head>
          <title>Fitness and Exercise Equipment Repair Parts</title>
          <meta
            name="description"
            content={'Find spare or replacement parts for exercise and fitness equipment. Use our diagrams and parts lists to locate the right part and extend the life of your treadmill, cross-trainer, bike, elliptical, stepper, or strength machine!'}
          />
          <meta httpEquiv="content-language" content="en-us" />
        </Head>
        <div className={styles.main_container}>
          <div className={styles.top_content_container}>
            <Render if={width >= 745}>
              <div className={styles.testimonials_container}>
                <Testimonials testimonials={testimonials} />
              </div>
            </Render>
            <div className={styles.carousel_and_cart_history_container}>
              <div className={styles.carousel_container}>
                <DynamicAdCarousel 
                  ads={ads}
                  adsLoading={getCarouselAdsMeta.loading}
                  width={width}
                  router={router}
                />
              </div>
              <Render if={accountType == 'residential'}>
                <div className={styles.guided_search_container}>
                  <GuidedEquipmentSearch
                    router={router}
                    brands={props.brands}
                    equipmentTypes={equipmentTypes}
                    equipmentSubTypes={equipmentSubtypes}
                    equipment={equipment}
                    getEquipmentTypes={getEquipmentTypes}
                    equipmentTypesMeta={getEquipmentTypesMeta}
                    getEquipmentSubtypesFromType={getEquipmentSubtypesFromType}
                    equipmentSubtypesMeta={getEquipmentSubtypesFromTypeMeta}
                    getEquipmentByBrandAndSubtype={getEquipmentByBrandAndSubtype}
                    equipmentMeta={getEquipmentByBrandAndSubtypeMeta}
                  />
                </div>
              </Render>
              <div className={styles.cart_history_container}>
                <CartHistory
                  data={cartHistory}
                  meta={getCartHistoryMeta}
                  screenWidth={width}
                  discountLevel={discountLevel}
                  addProductToCart={addProductToCart}
                  addProductToCartMeta={addProductToCartMeta}
                />
              </div>
              <Render if={accountType !== 'residential'}>
                <div className={styles.guided_search_container}>
                  <GuidedEquipmentSearch
                    router={router}
                    brands={props.brands}
                    equipmentTypes={equipmentTypes}
                    equipmentSubTypes={equipmentSubtypes}
                    equipment={equipment}
                    getEquipmentTypes={getEquipmentTypes}
                    equipmentTypesMeta={getEquipmentTypesMeta}
                    getEquipmentSubtypesFromType={getEquipmentSubtypesFromType}
                    equipmentSubtypesMeta={getEquipmentSubtypesFromTypeMeta}
                    getEquipmentByBrandAndSubtype={getEquipmentByBrandAndSubtype}
                    equipmentMeta={getEquipmentByBrandAndSubtypeMeta}
                  />
                </div>
              </Render>
            </div>
          </div>
          <div className={styles.bottom_content_container}>
            <ShopByBrands />
            <Welcome />
            <SearchTipsLink />
            <Render if={width <= 744}>
              <div className={styles.testimonials_container}>
                <Testimonials testimonials={testimonials} />
              </div>
            </Render>
          </div>
        </div>
      </>
    );
  }
};

export default Home;

function Welcome() {
  return (
    <div className={styles.welcome_message_container} itemScope itemType="http://schema.org/LocalBusiness">
      <div className={styles.section_title_container}>
        <h2 className={styles.title}>Welcome To FitnessRepairParts.com</h2>
        <Link href='/company'><a className={styles.link}>About Us &gt;</a></Link>
      </div>
      <p>
        Looking for Proform treadmill repair parts? How about a Life Fitness walking belt or that Nordic Track
        stop key? We maintain the largest online database of replacement parts for exercise and fitness equipment. Our unique
        and well organized cross reference search allows you to search by manufacturer part number or model number. Finding the
        right parts for your fitness equipment should be quick and easy.
      </p><br />
      <p>
        Please <a className={styles.link} href="/ticket.php?action=open">contact support via email</a> or through our Live Chat system for help. We 
        carry parts for sale for all the <Link href="/brand"><a className={styles.link}>major manufacturers</a></Link> such as: Bowflex, Schwinn, Monark, 
        Tunturi, Star Trac, Image, Health Rider, Proform, Weslo, Life Fitness, True Fitness, Precor, Cybex, and many more.
      </p>
    </div>
  );
}

function SearchTipsLink() {
  return (
    <div className={styles.search_tips_container}>
      <Link href="/searchtips"><a><img src="/images/help_nb_icon.png" alt="Help" /></a></Link>
      <Link href="/searchtips"><a className={styles.link}>How do I find parts?</a></Link>
    </div>
  );
}

function ShopByBrands() {
  return (
    <>
      <div className={styles.section_title_container}>
        <h2 className={styles.title}>Shop Parts By Brand/Manufacturer</h2>
        <Link href='/brand'><a className={styles.link}>See All Brands &gt;</a></Link>
      </div>
      <div className={styles.brands_display}>
        <Link href='/brand/sportsart'><a><img className={styles.brand_image} src="/brand_images/24.jpg" alt="SportsArt" /></a></Link>
        <Link href='/brand/nordictrack'><a><img className={styles.brand_image} src="/brand_images/43.jpg" alt="NordicTrack" /></a></Link>
        <Link href='/brand/parabody'><a><img className={styles.brand_image} src="/brand_images/52.jpg" alt="Parabody" /></a></Link>
        <Link href='/brand/bowflex'><a><img className={styles.brand_image} style={{ height: 12, margin: 20 }} src="/brand_images/66.jpg" alt="Bowflex" /></a></Link>
        <Link href='/brand/nautilus-residential'><a><img className={styles.brand_image} src="/brand_images/109.jpg" alt="Nautilus" /></a></Link>
      </div>
    </>
  );
}