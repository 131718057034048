import React from 'react';
import { Autocomplete, MenuItem, Tooltip } from '@mui/material';
import styles from './GuidedEquipmentSearch.module.css';

const initialSearch = { 
  brand: { id: '', name: '' },
  equipment_type: { id: '', name: '' },
  equipment_sub_type: { id: '', name: '' },
  equipment: { id: '', name: '', link: '' }
}

function GuidedEquipmentSearch(props) {

  const [search, setSearch] = React.useState(initialSearch);

  // Fetch data as user searches
  React.useEffect(() => {
    if (search.brand.id) {
      props.getEquipmentTypes(search.brand.id);
    }
  }, [search.brand]);
  
  React.useEffect(() => {
    if (search.equipment_type.id && search.brand.id) {
      props.getEquipmentSubtypesFromType(search.equipment_type.id, search.brand.id);
    }
  }, [search.equipment_type]);

  React.useEffect(() => {
    if (search.brand.id && search.equipment_sub_type.id) {
      props.getEquipmentByBrandAndSubtype(search.brand.id, search.equipment_sub_type.id);
    }
  }, [search.equipment_sub_type]);

  // Automatically set search if there is only one type, subtype, etc.
  React.useEffect(() => {
    if (props.equipmentTypes?.length == 1) {
      setSearch({ ...search, equipment_type: { id: props.equipmentTypes[0].equipment_type_id, name: props.equipmentTypes[0].equipment_type_name } })
    }
  }, [props.equipmentTypes]);

  React.useEffect(() => {
    if (props.equipmentSubTypes?.length == 1) {
      setSearch({ ...search, equipment_sub_type: { id: props.equipmentSubTypes[0].equipment_sub_type_id, name: props.equipmentSubTypes[0].equipment_sub_type_name } })
    }
  }, [props.equipmentSubTypes]);

  React.useEffect(() => {
    if (search.equipment.link && search.equipment.link !== '') {
      props.router.push(search.equipment.link);
    }
  }, [search.equipment.link]);

  function displayInstructions() {
    let loading = props.equipmentMeta?.loading || props.equipmentTypesMeta?.loading || props.equipmentSubtypesMeta?.loading;
    let brandSelected = ((search.brand.id && search.brand.id !== '') && (search.brand.name && search.brand.name !== ''));
    let typeSelected = ((search.equipment_type.id && search.equipment_type.id !== '') && (search.equipment_type.name && search.equipment_type.name !== ''));
    let subtypeSelected = ((search.equipment_sub_type.id && search.equipment_sub_type.id !== '') && (search.equipment_sub_type.name && search.equipment_sub_type.name !== ''));
    let equipmentSelected = ((search.equipment.id && search.equipment.id !== '') && (search.equipment.name && search.equipment.name !== ''));

    if (loading) {
      return ' ';
    } else if (!brandSelected) {
      return "Select your equipment's brand";
    } else if (!typeSelected) {
      return "Select the type of equipment you have";
    } else if (!subtypeSelected) {
      return "Select the sub-type of the equipment you have";
    } else if (!equipmentSelected) {
      return "Select your equipment";
    } else {
      return ' ';
    }
  }
  
  function displayBrands() {
    if ((!search.brand.id || search.brand.id == '') && (!search.brand.name || search.brand.name == '')) {
      return (
        <BrandDropdown 
          search={search}
          setSearch={setSearch}
          brands={props.brands}
        />
      );
    }

    return (
      <div style={{ paddingTop: 5 }}>
        <h4>Brand</h4>
        <p>{search.brand.name} 
          <span 
            style={{ cursor: 'pointer' }} 
            onClick={() => setSearch({ 
              brand: { id: '', name: '' },
              equipment_type: { id: '', name: '' }, 
              equipment_sub_type: { id: '', name: '' }, 
              equipment: { id: '', name: '', link: '' } 
            })}
          >
            &#128393;
          </span>
        </p>
      </div>
    );
  }
  
  function displayEquipmentTypes() {
    let brandSelected = ((search.brand.id || search.brand.id !== '') && (search.brand.name || search.brand.name !== ''));

    if ((!search.equipment_type.id || search.equipment_type.id == '') && (!search.equipment_type.name || search.equipment_type.name == '') && brandSelected && props.equipmentTypes?.length > 1) {
      return (
        <EquipmentTypeDropdown
          search={search}
          setSearch={setSearch}
          equipmentTypes={props.equipmentTypes}
        />
      );
    } else if ((!search.equipment_type.id || search.equipment_type.id !== '') && (!search.equipment_type.name || search.equipment_type.name !== '') && brandSelected && props.equipmentTypes?.length > 1) {
      return (
        <div style={{ paddingTop: 5 }}>
          <h4>Equipment Type</h4>
          <p>
            {search.equipment_type.name} 
            <span 
              style={{ cursor: 'pointer' }} 
              onClick={() => setSearch({ 
                ...search, 
                equipment_type: { id: '', name: '' }, 
                equipment_sub_type: { id: '', name: '' }, 
                equipment: { id: '', name: '', link: '' } 
              })}
            >
              &#128393;
            </span>
          </p>
        </div>
      );
    } else if (!brandSelected) {
      return null;
    }
  }
  
  function displayEquipmentSubtypes() {
    let typeSelected = ((search.equipment_type.id && search.equipment_type.id !== '') && (search.equipment_type.name && search.equipment_type.name !== ''));

    if (!typeSelected) {
      return null;
    } else if ((!search.equipment_sub_type.id || search.equipment_sub_type.id == '') && (!search.equipment_sub_type.name || search.equipment_sub_type.name == '') && typeSelected && props.equipmentSubTypes?.length > 1) {
      return (
        <EquipmentSubtypeDropdown
          search={search}
          setSearch={setSearch}
          equipmentSubTypes={props.equipmentSubTypes}
          equipmentSubTypesMeta={props.equipmentSubtypesMeta}
        />
      );
    } else if ((!search.equipment_sub_type.id || search.equipment_sub_type.id !== '') && (!search.equipment_sub_type.name || search.equipment_sub_type.name !== '') && props.equipmentSubTypes?.length > 1) {
      return (
        <div style={{ paddingTop: 5 }}>
          <h4>Equipment Subtype</h4>
          <p>{search.equipment_sub_type.name}
            <span 
              style={{ cursor: 'pointer' }} 
              onClick={() => setSearch({ 
                ...search, 
                equipment_sub_type: { id: '', name: '' }, 
                equipment: { id: '', name: '', link: '' } 
              })}
            >
              &#128393;
            </span>
          </p>
        </div>
      );
    }
  }
  
  function displayEquipment() {
    let subtypeSelected = (search.equipment_sub_type.id && search.equipment_sub_type.id !== '' && search.equipment_sub_type.name && search.equipment_sub_type.name !== '');

    if ((!search.equipment.id || search.equipment.id == '') && (!search.equipment.name || search.equipment.name == '') && subtypeSelected) {
      return (
        <EquipmentDropdown
          search={search}
          setSearch={setSearch}
          equipment={props.equipment}
          equipmentMeta={props.equipmentMeta}
        />
      );
    } else if (search.equipment.id !== '' && search.equipment.name !== '') {
      return (
        <div style={{ paddingTop: 5 }}>
          <h4>Model Name</h4>
          <p>{search.equipment.name} 
            <span 
              style={{ cursor: 'pointer' }} 
              onClick={() => setSearch({ 
                ...search,  
                equipment: { id: '', name: '', link: '' } 
              })}
            >
               &#128393;
            </span>
          </p>
        </div>
      );
    } else if (!subtypeSelected) {
      return null;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <img src='/misc_images/Guided_Search_Icon.png' width={150} />
      </div>
      <div className={styles.searchContainer}>
        <h2 className={styles.searchInstructions}>{displayInstructions()}</h2>
        <div className={styles.inputGroup}>
          {displayBrands()}
          {displayEquipmentTypes()}
          {displayEquipmentSubtypes()}
          {displayEquipment()}
        </div>
      </div>
    </div>
  );
}

export default GuidedEquipmentSearch;

function BrandDropdown(props) {

  function renderBrandOption(props, brand) {
    return (
      <MenuItem 
        {...props} 
        key={brand.id} 
        dense 
        disableGutters
        sx={{ fontSize: 12 }}
        value={brand.id}
      >
        {brand.name}
      </MenuItem>
    );
  }
  
  function getBrandLabel(option) {
    if (option.name) {
      return option.name;
    } else {
      return '';
    }
  }

  return (
    <div className={styles.inputContainer}>
      <Autocomplete 
        options={props.brands.length > 0 ? props.brands : []}
        value={props.search.brand}
        fullWidth
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              {...params.inputProps} 
              placeholder="Select Brand"
              className={styles.input}
              type='text'
            />
          </div>
        )}
        renderOption={renderBrandOption}
        getOptionLabel={getBrandLabel}
        onChange={(e, brand) => props.setSearch({ 
          ...props.search, 
          brand: { 
            id: brand?.id, 
            name: brand?.name 
          }, 
          equipment_type: { 
            id: '', 
            name: '' 
          }, 
          equipment_sub_type: { 
            id: '', 
            name: '' 
          }, 
          equipment: { 
            id: '', 
            name: '', 
            link: '' 
          } 
        })}
      />
    </div>
  );
}

function EquipmentTypeDropdown(props) {
  
  function renderEquipmentTypeOption(props, equipment_type) {
    return (
      <MenuItem 
        {...props} 
        key={equipment_type.equipment_type_id} 
        dense 
        disableGutters
        sx={{ fontSize: 12 }}
        value={equipment_type.equipment_type_id}
      >
        {equipment_type.equipment_type_name}
      </MenuItem>
    );
  }

  function getEquipmentTypeLabel(option) {
    if (option.name) {
      return option.name;
    } else if (option.equipment_type_name) {
      return option.equipment_type_name;
    } else {
      return '';
    }
  }

  return (
    <div className={styles.inputContainer}>
      <Autocomplete 
        loading={props.equipmentTypesMeta?.loading}
        options={props.equipmentTypes.length > 0 ? props.equipmentTypes : []}
        value={props.search.equipment_type}
        fullWidth={false}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              {...params.inputProps} 
              placeholder="Select Equipment Type"
              className={styles.input}
              type='text'
            />
          </div>
        )}
        renderOption={renderEquipmentTypeOption}
        getOptionLabel={getEquipmentTypeLabel}
        onChange={(e, equipment_type) => props.setSearch({ 
          ...props.search, 
          equipment_type: { 
            id: equipment_type?.equipment_type_id, 
            name: equipment_type?.equipment_type_name 
          }, 
          equipment_sub_type: { 
            id: '', 
            name: '' 
          } , 
          equipment: { 
            id: '', 
            name: '', 
            link: '' 
          }
        })}
      />
    </div>
  );
}

function EquipmentSubtypeDropdown(props) {
  
  function renderEquipmentSubtypeOption(props, equipment_sub_type) {
    return (
      <MenuItem 
        {...props} 
        key={equipment_sub_type.equipment_sub_type_id} 
        dense 
        disableGutters
        sx={{ fontSize: 12 }}
        value={equipment_sub_type.equipment_sub_type_id}
      >
        {equipment_sub_type.equipment_sub_type_name}
      </MenuItem>
    );
  }
  
  function getEquipmentSubtypeLabel(option) {
    if (option.name) {
      return option.name;
    } else if (option.equipment_sub_type_name) {
      return option.equipment_sub_type_name;
    } else {
      return '';
    }
  }

  return (
    <div className={styles.inputContainer}>
      <Autocomplete 
        loading={props.equipmentSubTypesMeta?.loading}
        options={props.equipmentSubTypes.length > 0 ? props.equipmentSubTypes : []}
        value={props.search.equipment_sub_type}
        fullWidth={false}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              {...params.inputProps} 
              placeholder="Select Equipment Subtype"
              className={styles.input}
              type='text'
            />
          </div>
        )}
        renderOption={renderEquipmentSubtypeOption}
        getOptionLabel={getEquipmentSubtypeLabel}
        onChange={(e, equipment_sub_type) => props.setSearch({ 
          ...props.search, 
          equipment_sub_type: { 
            id: equipment_sub_type?.equipment_sub_type_id, 
            name: equipment_sub_type?.equipment_sub_type_name 
          }, 
          equipment: { 
            id: '', 
            name: '', 
            link: '' 
          } 
        })}
      />
    </div>
  );
}

function EquipmentDropdown(props) {

  function renderEquipmentOption(props, equipment) {
    if (equipment.equipment_name) {
      return (
        <Tooltip title={equipment.equipment_name}>
          <MenuItem 
            {...props} 
            key={equipment.equipment_id} 
            dense 
            disableGutters
            sx={{ fontSize: 12 }}
            value={equipment.equipment_id}
          >
            {equipment.equipment_name}
          </MenuItem>
        </Tooltip>
      );
    } else if (equipment.name) {
      return (
        <Tooltip title={equipment.name}>
          <MenuItem 
            {...props} 
            key={equipment.equipment_id} 
            dense 
            disableGutters
            sx={{ fontSize: 12 }}
            value={equipment.equipment_id}
          >
            {equipment.name}
          </MenuItem>
        </Tooltip>
      );
    }
  }
  
  function getEquipmentLabel(option) {
    if (option.equipment_name) {
      return option.equipment_name;
    } else if (option.name) {
      return option.name;
    }

    return '';
  }

  return (
    <div className={styles.inputContainer}>
      <Autocomplete 
        loading={props.equipmentMeta?.loading}
        options={props.equipment?.length > 0 ? props.equipment : []}
        value={props.search.equipment}
        fullWidth={false}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              {...params.inputProps} 
              placeholder="Select Your Model"
              className={styles.input}
              type='text'
            />
          </div>
        )}
        renderOption={renderEquipmentOption}
        getOptionLabel={getEquipmentLabel}
        onChange={(e, equipment) => props.setSearch({ 
          ...props.search, 
          equipment: { 
            id: equipment?.equipment_id, 
            name: equipment?.equipment_name, 
            link: equipment?.link 
          } 
        })}
      />
    </div>
  );
}