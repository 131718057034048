import React from 'react';
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Skeleton } from '@mui/material';

export default function DynamicAdCarousel(props) {

  function getAdWithLinkStyles(ad) {
    return {
      backgroundImage: 'url(' + ad.image + ')', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      height: 400, 
      cursor: 'pointer'
    }
  }
  
  function getAdWithoutLinkStyles(ad) {
    return {
      backgroundImage: 'url(' + ad.image + ')', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      height: 400
    }
  }

  return (
      <Swiper
        loop
        height={200}
        spaceBetween={10}
        navigation
        autoplay={{ delay: 1000, disableOnInteraction: true }}
        modules={[Navigation, Autoplay]}
        onSwiper={() => {}}
        slidesPerView={props.width >= 993 ? 2.1 : 1.1}
        onSlideChange={() => {}}
      >
        {props.adsLoading ?
          <SwiperSlide>
              <Skeleton variant='rectangular' height={400} width={435} />
          </SwiperSlide>
        :
          props.ads?.map(ad => {
            if (ad.active) {
              return (
                <SwiperSlide>
                  <div
                    key={ad.id}
                    style={ad.link == '' ? getAdWithoutLinkStyles(ad) : getAdWithLinkStyles(ad)}
                    onClick={ad.link == '' ? () => {} : () => props.router.push(ad.link)}
                  >
                  </div>
                </SwiperSlide>
              )
            }
          })
        }
      </Swiper>
  );
}