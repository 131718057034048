import { prefix, ajax } from './common';

export async function getEquipment(id: number) {
  return await ajax({
    url: prefix + '/equipment/' + id
  });
}

export async function getEquipmentByBrandAndSubtype(brand_id, subtype_id) {
  return await ajax({
    url: prefix + '/equipment',
    data: {
      brand_id,
      subtype_id
    }
  });
}

export async function getModel(id: number) {
  return await ajax({
    url: prefix + '/models/' + id
  });
}

export async function getEquipmentTypesForBrand(brand_id) {
  return await ajax({
    url: prefix + '/equipment-types',
    data: {
      brand_id
    }
  });
}

export async function getEquipmentByBrand(brand_id: number) {
  return await ajax({
    url: prefix + '/brands/' + brand_id + '/unsorted-equipment',
  });
}

export async function getEquipmentWithoutParts(pagination = 25, page = 1) {
  return await ajax({
    url: prefix + '/reports/cataloging/equipment-with-no-parts',
    data: {
      pagination: pagination, 
      page
    }
  });
}

export async function getInactiveEquipment(brand_id = '') {
  return await ajax({
    url: prefix + '/equipment/inactive',
    data: {
      brand_id: brand_id
    }
  });
}

export async function getEquipmentTypes() {
  return await ajax({
    url: prefix + '/equipment-types',
  });
}

export async function getEquipmentSubtypes() {
  return await ajax({
    url: prefix + '/equipment-subtypes',
  });
}

export async function getEquipmentSubtypesFromType(type_id, brand_id) {
  return await ajax({
    url: prefix + '/equipment-types/' + type_id + '/subtypes',
    data: {
      brand_id
    }
  });
}

export async function updateEquipment(id: number, form_data) {
  return await ajax({
    method: 'PUT',
    url: prefix + '/equipment/' + id,
    data: {
      ...form_data
    }
  });
}

export async function createEquipment(form_data) {
  return await ajax({
    method: 'POST',
    url: prefix + '/equipment',
    data: {
      ...form_data
    }
  });
}

export async function deleteEquipment(id: number) {
  return await ajax({
    method: 'DELETE',
    url: prefix + '/equipment/' + id,
  });
}

export async function getEquipmentAssociations(id: number) {
  return await ajax({
    url: prefix + '/equipment/' + id + '/associations',
  });
}

export async function createDefaultWarranty(id: number, data) {
  return await ajax({
    method: 'POST',
    url: prefix + '/equipment/' + id + '/add-default-warranty',
    data: {
      title: data.title,
      description: data.description,
      labor_warranty: data.labor_warranty,
      parts_warranty: data.parts_warranty
    }
  });
}