import React from 'react';
import styles from './Testimonials.module.css';

function Testimonials(props) {

  const [expandedTestimonials, setExpandedTestimonials] = React.useState([]);

  function formatTestimonial(text, id) {
    if (text?.includes('\n')) {
      const text_array = text?.split('\n');

      return text_array.map((text, index) => {
        return (
          <p
            dangerouslySetInnerHTML={{ __html: text }}
            className={styles.testimonial_text}
            key={index}
          />
        );
      });
    }
    
    if (text.includes(' . . . ') && !expandedTestimonials.includes(id)) {
      return (
        <>
          <p className={styles.testimonial_text}>{text}</p>
          <span className={styles.expand_button} onClick={() => setExpandedTestimonials([...expandedTestimonials, id])}>(read more)</span>
        </>
      );
    }
    
    if (text.length > 140 && expandedTestimonials) {
      return (
        <>
          <p className={styles.testimonial_text}>{text}</p>
          <span onClick={() => removeTestimonialFromState(id)}>(show less)</span>
        </>
      )
    }

    return <p className={styles.testimonial_text}>{text}</p>
  }

  function shortenLongTestimonial(testimonial) {
    let testimonial_text = testimonial.text;
    if (testimonial.text.length > 140 && !expandedTestimonials.includes(testimonial.testimonial_id)) {
      testimonial_text = testimonial.text.slice(0, 139) + ' . . . ';
    } else if (testimonial.text.length > 140 && expandedTestimonials.includes(testimonial.testimonial_id)) {
      return (
        <>
          <p className={styles.testimonial_text}>
            {testimonial.text}
          </p>
          <span className={styles.expand_button} onClick={() => removeTestimonialFromState(testimonial.testimonial_id)}>(show less)</span>
        </>
      )
    }

    return formatTestimonial(testimonial_text, testimonial.testimonial_id);
  }

  function removeTestimonialFromState(id) {
    setExpandedTestimonials(expandedTestimonials.filter(testimonial => {
      if (testimonial !== id) {
        return testimonial;
      }
    }))
  }

  return (
    <div className={styles.testimonials_container}>
      <h2 className={styles.testimonials_title}>Testimonials</h2>
      <div className={styles.testimonials_box}>
        {props.testimonials?.map(testimonial => {
          return (
            <div key={testimonial.testimonial_id} className={styles.testimonial}>
              {shortenLongTestimonial(testimonial)}
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default Testimonials;